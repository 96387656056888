.table {
  td {
    white-space: nowrap;
  }

  th {
    white-space: nowrap;
  }
}

.collectionLink {
  :hover {
    color: var(--mb-color-brand) !important;
  }
}
